import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/Home.vue';
import About from './components/About.vue';
import Projects from './components/Projects.vue';
import Contact from './components/Contact.vue';
import FAQ from './components/FAQ.vue';
import Blog from './components/Blog.vue';
import BlogPost from './components/BlogPost.vue';
import Services from './components/Services.vue';
import Testimonials from './components/Testimonials.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/projects', component: Projects },
  { path: '/contact', component: Contact },
  { path: '/faq', component: FAQ },
  { path: '/blog', component: Blog },
  {
    path: '/blog/:id',
    name: 'BlogPost',
    component: BlogPost,
    props: true,
  },
  { path: '/services', component: Services },
  { path: '/testimonials', component: Testimonials },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
