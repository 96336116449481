<template>
  <div class="projects-page">
    <head>
      <title>Projets de Développement Web | Ibrahim Ouahabi</title>
      <meta name="description" content="Découvrez les projets récents de développement web réalisés par Ibrahim Ouahabi, un développeur web expérimenté basé à Compiègne." />
      <meta name="keywords" content="Projets Développement Web, Ibrahim Ouahabi, Compiègne, Vue.js, Node.js" />
    </head>
    <section class="projects-introduction">
      <h1>Mes Projets</h1>
      <p>Voici une sélection des projets sur lesquels j'ai travaillé. Cliquez sur les titres pour en savoir plus.</p>
    </section>

    <section class="projects-list">
      <div v-for="project in projects" :key="project.id" class="project-item">
        <img v-if="project.image" :src="project.image" :alt="project.name" class="project-image"/>
        <h2>{{ project.name }}</h2>
        <p>{{ project.description }}</p>
        <a :href="project.url" target="_blank" class="btn-view">Voir le projet</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ProjectsPage",
  data() {
    return {
      projects: [
        {
          id: 1,
          name: "Zorglux",
          description: "Zorglux est un projet pour mon cours de Référencement. Il fallait créer un site web et le référencer avec le mot-clé 'Zorglux'.",
          url: "https://github.com/Narutino10/zorglux",
          image: require("../assets/Capture.PNG")
        },
        {
          id: 2,
          name: "TP VueJS2",
          description: "Ce projet était pour un cours de VueJS, où nous avons travaillé sur la récupération de données au format JSON pour créer un système de tableau d'utilisateurs et filtrer les données en fonction de l'ID utilisateur.",
          url: "https://github.com/Narutino10/vuejstp",
          image: require("../assets/TP2.PNG")
        },
        {
          id: 3,
          name: "MarketPlace",
          description: "Ce projet était pour un projet semestriel en NodeJS, VueJS avec TypeScript et RGPD. Nous avons créé un site complet de vente d'équipements sportifs avec gestion d'alertes, inscription avec confirmation par mail, gestion des stocks, etc.",
          url: "https://github.com/Narutino10/marketplace",
          image: require("../assets/marketplace.PNG")
        }
      ]
    };
  }
};
</script>

<style scoped>
.projects-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.projects-introduction {
  text-align: center;
  margin-bottom: 40px;
}

.projects-introduction h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #4CAF50;
}

.projects-introduction p {
  font-size: 1.2em;
  color: #666;
}

.projects-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.project-item {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.project-item h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.project-item p {
  color: #777;
  line-height: 1.6;
}

.btn-view {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-view:hover {
  background-color: #45a049;
}
</style>
