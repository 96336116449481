<template>
    <div class="home-page">
      <head>
      <title>Ibrahim Ouahabi | Développeur Web à Compiègne</title>
      <meta name="description" content="Bienvenue sur le portfolio d'Ibrahim Ouahabi, développeur web passionné à Compiègne. Découvrez mes projets et compétences en développement front-end et back-end." />
      <meta name="keywords" content="Ibrahim Ouahabi, Développeur Web, Compiègne, Développement Web, Front-End, Back-End" />
      </head>
      <section class="introduction">
        <h1>Bienvenue sur mon Portfolio</h1>
        <p>Je suis Ibrahim, un étudiant développeur web à l'ESGI passionné spécialisé en développement front-end et back-end. Découvrez mes projets et compétences ci-dessous.</p>
        <img src="../assets/Photo.PNG" alt="Photo de profil" class="profil-photo">
      </section>
  
      <section class="skills-overview animated fadeIn">
        <h2>Mes Compétences Principales</h2>
        <ul>
          <li><img src="https://www.svgrepo.com/show/331488/mongodb.svg" alt="MongoDB"> MongoDB</li>
          <li><img src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg" alt="Vue.js"> Vue.js</li>
          <li><img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" alt="Node.js"> Node.js</li>
        </ul>
      </section>
  
      <section class="featured-projects animated slideInUp">
        <h2>Projets Récents</h2>
        <div class="projects-grid">
          <div class="project-item">
            <div class="project-image">
              <img src="../assets/marketplace.PNG" alt="Project 1">
            </div>
            <h3>Marketplace</h3>
            <p>Un projet intéressant sur lequel j'ai travaillé récemment.</p>
            <a href="https://github.com/Narutino10/marketplace">En savoir plus</a>
          </div>
          <div class="project-item">
            <div class="project-image">
              <img src="../assets/TP2.PNG" alt="Project 2">
            </div>
            <h3>TP recupération données JSON</h3>
            <p>Un autre projet captivant réalisé avec Vue.js.</p>
            <a href="https://github.com/Narutino10/vuejstp">En savoir plus</a>
          </div>
        </div>
      </section>
  
      <section class="call-to-action animated fadeIn">
        <h2>Intéressé par mon travail?</h2>
        <p>Contactez-moi pour discuter de vos projets ou consultez mes travaux précédents.</p>
        <a class="cta-button"><router-link to="/contact" @click="closeMenu">Contactez-moi</router-link></a>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "HomePage",
  };
  </script>
  
  <style scoped>
  .home-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .introduction {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .introduction h1 {
    font-size: 3em;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    animation: fadeIn 1s ease-out;
  }
  
  .introduction p {
    font-size: 1.2em;
    color: #777;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    animation: fadeIn 1.5s ease-out;
  }
  
  .profil-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 20px;
    animation: fadeIn 2s ease-out;
  }
  
  .skills-overview {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .skills-overview h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #4CAF50;
  }
  
  .skills-overview ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .skills-overview li {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .skills-overview li:hover {
    transform: translateY(-5px);
  }
  
  .skills-overview img {
    width: 50px;
    height: 50px;
  }
  
  .featured-projects {
    margin-bottom: 60px;
  }
  
  .featured-projects h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
    color: #4CAF50;
  }
  
  .projects-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .project-item {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    max-width: 300px; /* Limiter la largeur de l'élément projet */
    text-align: center;
  }
  
  .project-item:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .project-image {
    width: 100%;
    height: 200px; /* Fixer la hauteur de l'image */
    overflow: hidden; /* Masquer tout ce qui dépasse */
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajuster l'image pour couvrir tout le conteneur */
  }
  
  .project-item h3 {
    font-size: 1.5em;
    margin-top: 15px;
  }
  
  .project-item p {
    font-size: 1em;
    color: #555;
  }
  
  .call-to-action {
    text-align: center;
    margin: 60px 0;
  }
  
  .call-to-action h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .call-to-action p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #45a049;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInUp {
    from { transform: translateY(100px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }
  
  .slideInUp {
    animation-name: slideInUp;
  }
  </style>
  