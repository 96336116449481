<template>
  <div class="about-page">
    <head>
      <title>À Propos de Ibrahim Ouahabi | Développeur Web à Compiègne</title>
      <meta name="description" content="Découvrez l'histoire et les compétences d'Ibrahim Ouahabi, développeur web spécialisé en front-end et back-end à Compiègne." />
      <meta name="keywords" content="Ibrahim Ouahabi, À propos, Développeur Web, Compiègne, Développement Front-End, Développement Back-End" />
    </head>
    <section class="introduction">
      <h1>À Propos de Moi</h1>
      <p>Bienvenue ! Je suis Ibrahim, un Développeur Web passionné avec une solide expérience dans ce domaine.</p>
      <p>Je suis actuellement en 5e année à l'ESGI Paris en Ingénierie du Web.</p>
    </section>

    <section class="skills">
      <h2>Mes Compétences</h2>
      
      <div class="skill-category">
        <h3>Frontend</h3>
        <ul>
          <li>
            <img src="https://www.svgrepo.com/show/303205/html-5-logo.svg" alt="HTML" class="skill-logo">
            HTML
          </li>
          <li>
            <img src="https://www.svgrepo.com/show/349330/css3.svg" alt="CSS" class="skill-logo">
            CSS/SCSS
          </li>
          <li>
            <img src="https://www.svgrepo.com/show/349419/javascript.svg" alt="JavaScript" class="skill-logo">
            JavaScript
          </li>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg" alt="Vue.js" class="skill-logo">
            Vue.js
          </li>
          <li>
            <img src="https://www.svgrepo.com/show/355190/reactjs.svg" alt="React" class="skill-logo">
            React
          </li>
          <li>
            <img :src="require('../assets/twig.svg')" alt="Twig" class="skill-logo">
            Twig
          </li>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg" alt="TailwindCSS" class="skill-logo">
            TailwindCSS
          </li>
        </ul>
      </div>

      <div class="skill-category">
        <h3>Backend</h3>
        <ul>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" alt="Node.js" class="skill-logo">
            Node.js
          </li>
          <li>
            <img src="https://symfony.com/logos/symfony_black_03.svg" alt="Symfony" class="skill-logo">
            Symfony
          </li>
        </ul>
      </div>

      <div class="skill-category">
        <h3>Bases de Données</h3>
        <ul>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg" alt="PostgreSQL" class="skill-logo">
            PostgreSQL
          </li>
          <li>
            <img src="https://www.svgrepo.com/show/331488/mongodb.svg" alt="MongoDB" class="skill-logo">
            MongoDB
          </li>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/0/0a/MySQL_textlogo.svg" alt="MySQL" class="skill-logo">
            MySQL
          </li>
          <li>
            <img :src="require('../assets/HFSQL.png')" alt="HFSQL" class="skill-logo">
            HFSQL
          </li>
        </ul>
      </div>

      <div class="skill-category">
        <h3>Outils</h3>
        <ul>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/3/3f/Git_icon.svg" alt="Git" class="skill-logo">
            Git
          </li>
          <li>
            <img src="https://www.svgrepo.com/show/331370/docker.svg" alt="Docker" class="skill-logo">
            Docker
          </li>
          <li>
            <img :src="require('../assets/Burp.png')" alt="Burp" class="skill-logo">
            Burp Suite
          </li>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg" alt="Figma" class="skill-logo">
            Figma
          </li>
        </ul>
      </div>

      <div class="skill-category">
        <h3>Autres</h3>
        <ul>
          <li>
            <img :src="require('../assets/Windev.jpg')" alt="Windev" class="skill-logo">
            Windev
          </li>
          <li>
            <img :src="require('../assets/Windev.jpg')" alt="Windev Mobile" class="skill-logo">
            Windev Mobile
          </li>
          <li>
            <img :src="require('../assets/Windev.jpg')" alt="WebDev" class="skill-logo">
            WebDev
          </li>
        </ul>
      </div>
    </section>

    <section class="experience">
      <h2>Expérience Professionnelle</h2>
      <div class="experience-item">
        <h3>Développeur Logiciel chez Pyromeral Systems</h3>
        <span>Septembre 2022 - Présent</span>
        <p>Développement d'un logiciel permettant de remplacer le papier et d'automatiser la génération de documents, responsable du développement complet du logiciel.</p>
      </div>
      <div class="experience-item">
        <h3>Dépannage Informatique</h3>
        <span>2016 - 2020</span>
        <p>Stages pour mon Bac Pro et BTS, principalement axés sur le montage de tours, le dépannage et la recondition de matériel informatique dans diverses entreprises telles que PCexpress, Webhelp, Recyclerie de Compiègne, Aristechnologie. J'ai également configuré des caméras de surveillance pour des clients.</p>
      </div>
      <div class="experience-item">
        <h3>Activité Auto-entrepreneur</h3>
        <span>Mars 2021 - Présent</span>
        <p>
          Activité de livraison a vélo sur différentes plateforme tels que
          UberEats et Deliveroo dans la zone de Compiègne.
        </p>
      </div>
      <div class="experience-item">
        <h3>Vacation Parc Asterix</h3>
        <span>Juin 2019 - Présent</span>
        <p>
          Employer Polyvalent au parc Astérix, dans la restauration, les stands des Jeux, Hôtellerie.
        </p>
      </div>
    </section>

    <section class="education">
      <h2>Formation</h2>
      <div class="education-item">
        <h3>Mastère Ingénierie du Web</h3>
        <span>ESGI Paris, 2023 - 2025</span>
        <p>Spécialisation en développement web, structures de données, algorithmes, et différents frameworks (Symfony, Next.js, NestJS, Node.js, Vue.js, etc.).</p>
      </div>
      <div class="education-item">
        <h3>Bachelor Ingénierie du Web</h3>
        <span>ESGI Paris, 2022 - 2023</span>
        <p>Apprentissage des fondamentaux du développement web, incluant HTML, CSS/SCSS, JavaScript, et PHP from scratch.</p>
      </div>
      <div class="education-item">
        <h3>BTS SNIR</h3>
        <span>Espace Scolaire Condorcet Saint-Quentin, 2019 - 2021</span>
        <p>Apprentissage des fondamentaux du développement, notamment le C/C++, le PHP, HTML, CSS, Git.</p>
      </div>
      <div class="education-item">
        <h3>Bac Pro SN Option ARED</h3>
        <span>Lycée Mireille Grenet Compiègne, 2016 - 2019</span>
        <p>Apprentissage de la mise en service de caméra de surveillance, un peu d'HTML/CSS, Dépannage d'électroménager, Métier de la fibre optique.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  mounted() {
    // Script d'animation au défilement
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.experience-item, .education-item, ul li');
    hiddenElements.forEach(el => el.classList.add('hidden'));
    hiddenElements.forEach(el => observer.observe(el));
  }
};
</script>

<style scoped>
.about-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.introduction {
  text-align: center;
  margin-bottom: 60px;
}

.introduction h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #333;
  font-weight: 700;
}

.introduction p {
  font-size: 1.2em;
  color: #777;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.skills, .experience, .education {
  margin-bottom: 60px;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #4CAF50;
  position: relative;
  font-weight: 700;
}

h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  height: 3px;
  background-color: #4CAF50;
}

h3 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
}

.skill-category {
  margin-bottom: 40px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  background: #f9f9f9;
  margin: 10px 0;
  padding: 15px;
  border-left: 5px solid #4CAF50;
  font-size: 1.1em;
  border-radius: 5px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
}

ul li:hover {
  transform: translateX(10px);
}

.skill-logo {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.experience-item, .education-item {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.experience-item:hover, .education-item:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.experience-item h3, .education-item h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.experience-item span, .education-item span {
  display: block;
  font-size: 0.9em;
  color: #999;
  margin-bottom: 15px;
}

.experience-item p, .education-item p {
  margin: 0;
  color: #555;
  line-height: 1.6;
}

/* Animations au défilement */
.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .about-page {
    padding: 15px;
  }

  .introduction h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }

  ul li {
    font-size: 1em;
  }

  .skill-logo {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}
</style>
