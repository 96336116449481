<template>
    <div class="blog-page">
      <head>
        <title>Blog sur le Développement Web | Ibrahim Ouahabi</title>
        <meta name="description" content="Lisez les articles et astuces sur le développement web par Ibrahim Ouahabi, développeur web basé à Compiègne." />
        <meta name="keywords" content="Blog Développement Web, Ibrahim Ouahabi, Compiègne, Articles Développement Web" />
      </head>
      <section class="blog-introduction">
        <h1>Blog</h1>
        <p>Explorez mes articles sur le développement web, les technologies et bien plus encore.</p>
      </section>
  
      <section class="blog-list">
        <div v-for="post in blogPosts" :key="post.id" class="blog-item">
          <h2>{{ post.title }}</h2>
          <p class="blog-date">{{ post.date }}</p>
          <p class="blog-summary">{{ post.summary }}</p>
          <router-link :to="{ name: 'BlogPost', params: { id: post.id } }" class="btn-read-more">Lire la suite</router-link>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "BlogPage",
    data() {
      return {
        blogPosts: [
          {
            id: 1,
            title: "Les meilleures pratiques en développement web en 2024",
            date: "1er Janvier 2024",
            summary: "Découvrez les dernières tendances et les meilleures pratiques en développement web pour l'année 2024."
          },
          {
            id: 2,
            title: "Comment améliorer les performances de votre site web",
            date: "15 Février 2024",
            summary: "Apprenez à optimiser les performances de votre site web avec ces techniques simples et efficaces."
          }
          // Ajoutez d'autres articles ici
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .blog-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .blog-introduction {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .blog-introduction h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  .blog-introduction p {
    font-size: 1.2em;
    color: #666;
  }
  
  .blog-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .blog-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-item h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .blog-date {
    font-size: 0.9em;
    color: #999;
  }
  
  .blog-summary {
    color: #777;
    line-height: 1.6;
  }
  
  .btn-read-more {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn-read-more:hover {
    background-color: #45a049;
  }
  </style>
  