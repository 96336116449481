<template>
    <div class="faq-page">
      <head>
      <title>FAQ sur les Services de Développement Web | Ibrahim Ouahabi</title>
      <meta name="description" content="Trouvez les réponses aux questions fréquentes concernant les services de développement web d'Ibrahim Ouahabi." />
      <meta name="keywords" content="FAQ Développement Web, Ibrahim Ouahabi, Compiègne, Questions Fréquentes" />
    </head>
      <section class="faq-introduction">
        <h1>Foire Aux Questions</h1>
        <p>Vous trouverez ici les réponses aux questions les plus fréquentes.</p>
      </section>
  
      <section class="faq-list">
        <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
          <div class="faq-question" @click="toggleFaq(index)">
            <h2>{{ faq.question }}</h2>
            <span v-if="faq.open">-</span>
            <span v-else>+</span>
          </div>
          <div v-if="faq.open" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "FAQPage",
    data() {
      return {
        faqs: [
          {
            question: "Quels services proposez-vous ?",
            answer: "Je propose des services de développement web frontend et backend, ainsi que des consultations en sécurité.",
            open: false
          },
          {
            question: "Comment puis-je vous contacter ?",
            answer: "Vous pouvez me contacter via le formulaire de contact sur la page dédiée ou directement par email à ibrahim.ouahahbi@outlook.fr.",
            open: false
          },
          {
            question: "Travaillez-vous sur des projets freelance ?",
            answer: "Oui, je suis disponible pour des projets freelance. N'hésitez pas à me contacter pour discuter de vos besoins.",
            open: false
          },
          {
            question: "Quelle est votre méthodologie de travail ?",
            answer: "Je travaille en suivant la méthodologie Agile, en utilisant des itérations courtes et des livraisons fréquentes pour garantir la satisfaction du client.",
            open: false
          }
          // Ajoutez d'autres questions et réponses ici
        ]
      };
    },
    methods: {
      toggleFaq(index) {
        this.faqs[index].open = !this.faqs[index].open;
      }
    }
  };
  </script>
  
  <style scoped>
  .faq-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .faq-introduction {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .faq-introduction h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  .faq-introduction p {
    font-size: 1.2em;
    color: #666;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .faq-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .faq-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .faq-question h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .faq-answer {
    margin-top: 20px;
    font-size: 1.1em;
    color: #777;
    line-height: 1.6;
  }
  
  .faq-question span {
    font-size: 1.5em;
    color: #4CAF50;
  }
  </style>
  