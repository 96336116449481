<template>
    <div class="testimonials-page">
      <section class="testimonials-introduction">
        <h1>Témoignages</h1>
        <p>Découvrez ce que disent mes clients et collaborateurs de mon travail.</p>
      </section>
  
      <section class="testimonials-list">
        <div v-for="testimonial in testimonials" :key="testimonial.id" class="testimonial-item">
          <p class="testimonial-text">"{{ testimonial.text }}"</p>
          <h3 class="testimonial-author">{{ testimonial.author }}</h3>
          <p class="testimonial-position">{{ testimonial.position }}</p>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "TestimonialsPage",
    data() {
      return {
        testimonials: [
          {
            id: 1,
            text: "Ibrahim a fait un travail exceptionnel sur notre projet. Son expertise en développement web est inégalée.",
            author: "John Doe",
            position: "CEO, Entreprise XYZ"
          },
          {
            id: 2,
            text: "Travailler avec Ibrahim a été un vrai plaisir. Il a une grande attention aux détails et est très professionnel.",
            author: "Jane Smith",
            position: "Chef de projet, Entreprise ABC"
          }
          // Ajoutez d'autres témoignages ici
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .testimonials-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .testimonials-introduction {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .testimonials-introduction h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  .testimonials-introduction p {
    font-size: 1.2em;
    color: #666;
  }
  
  .testimonials-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .testimonial-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-text {
    font-style: italic;
    font-size: 1.1em;
    color: #555;
  }
  
  .testimonial-author {
    font-weight: bold;
    font-size: 1.3em;
    color: #333;
    margin-top: 15px;
  }
  
  .testimonial-position {
    font-size: 1em;
    color: #999;
  }
  </style>
  