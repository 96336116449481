<template>
    <div class="blog-post">
      <head>
        <title>{{ postTitle }} | Blog de Ibrahim Ouahabi</title>
        <meta name="description" content="Lisez cet article sur {{ postTopic }} par Ibrahim Ouahabi, développeur web basé à Compiègne." />
        <meta name="keywords" content="{{ postKeywords }}, Blog Développement Web, Ibrahim Ouahabi" />
      </head>
      <h1>{{ post.title }}</h1>
      <p>{{ post.content }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "BlogPost",
    props: ['id'],
    data() {
      return {
        post: {
          title: "Article de blog",
          content: "Ceci est le contenu de l'article de blog."
        },
      };
    },
    created() {
      // Vous pouvez remplacer ce code par une véritable requête pour récupérer le contenu du post via l'ID
      console.log('Post ID:', this.id);
      // Ex: fetchPostById(this.id).then(post => { this.post = post })
    }
  };
  </script>
  
  <style scoped>
  .blog-post {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  