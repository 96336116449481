<template>
  <head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="description" content="Portfolio d'Ibrahim Ouahabi, Développeur Web à Compiègne, spécialisé en front-end et back-end.">
  <meta name="keywords" content="Ibrahim Ouahabi, Compiègne, développeur web, dépannage informatique, Vue.js, Node.js">
  <meta property="og:title" content="Portfolio d'Ibrahim Ouahabi">
  <meta property="og:description" content="Développeur Web passionné, basé à Compiègne, spécialisé en front-end et back-end.">
  <meta property="og:image" content="https://ibrahimouahabi.fr/assets/profile-pic.jpg">
  <meta property="og:url" content="https://ibrahimouahabi.fr">
  <meta name="twitter:card" content="summary_large_image">
  <title>Portfolio Ibrahim Ouahabi</title>
</head>

  <div id="app">
    <!-- En-tête ou barre de navigation -->
    <nav class="navbar" role="navigation" aria-label="Main navigation">
      <div class="navbar-brand">
        <router-link to="/">Portfolio Ibrahim Ouahabi</router-link>
      </div>
      <div class="navbar-toggle" @click="toggleMenu" aria-label="Toggle menu">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div :class="['navbar-menu', { 'is-active': menuActive }]">
        <router-link to="/" @click="closeMenu">Accueil</router-link>
        <router-link to="/about" @click="closeMenu">À Propos</router-link>
        <router-link to="/projects" @click="closeMenu">Projets</router-link>
        <router-link to="/contact" @click="closeMenu">Contact</router-link>
        <router-link to="/services" @click="closeMenu">Services</router-link>
        <router-link to="/blog" @click="closeMenu">Blog</router-link>
        <router-link to="/faq" @click="closeMenu">FAQ</router-link>
      </div>
    </nav>

    <router-view />

    <footer class="site-footer">
      <div class="footer-content">
        <h3>Contactez-moi</h3>
          <div class="social-links">
            <a href="https://github.com/Narutino10" target="_blank" aria-label="GitHub de Ibrahim Ouahabi">
              <img src="https://www.svgrepo.com/show/349375/github.svg" alt="GitHub" class="social-icon">
            </a>
            <a href="https://www.linkedin.com/in/ibrahim-ouahabi" target="_blank" aria-label="LinkedIn de Ibrahim Ouahabi">
              <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Linkedin_icon.svg" alt="LinkedIn" class="social-icon">
            </a>
            <a href="https://www.instagram.com/ibrahim.ohb" target="_blank" aria-label="Instagram de Ibrahim Ouahabi">
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" alt="Instagram" class="social-icon">
            </a>
            <a href="mailto:ibrahim.ouahahbi@outlook.fr" aria-label="Envoyer un email à Ibrahim Ouahabi">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/Eo_circle_white_letter-i.svg" alt="Email" class="social-icon">
            </a>
          </div>
        <p>&copy; 2024 Ibrahim - Tous droits réservés.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      menuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    closeMenu() {
      this.menuActive = false;
    },
  },
};
</script>

<style>
/* Styles globaux */
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
}

.navbar-brand a {
  font-weight: bold;
  color: #42b983;
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  gap: 20px;
}

.navbar-menu a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.navbar-menu a:hover {
  color: #42b983;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  cursor: pointer;
}

.navbar-toggle span {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #fff;
}

/* Footer styles */
.site-footer {
  background-color: #333;
  padding: 20px 0;
  color: #fff;
  text-align: center;
  margin-top: 40px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content h3 {
  margin-bottom: 20px;
  font-size: 1.8em;
}

.social-links {
  margin-bottom: 20px;
}

.social-links a {
  margin: 0 10px;
  display: inline-block;
}

.social-icon {
  width: 30px;
  height: 30px;
  filter: invert(0%);
  transition: transform 0.3s ease;
  /* Assurez-vous que toutes les icônes ont la même taille */
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 5px;
}

.social-icon:hover {
  transform: scale(1.1);
  filter: none; 
}

.footer-content p {
  margin-top: 20px;
  font-size: 1em;
  color: #ccc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #333;
    text-align: center;
  }

  .navbar-menu.is-active {
    display: flex;
  }

  .navbar-toggle {
    display: flex;
  }
}
</style>
