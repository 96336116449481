<template>
  <div class="contact-page">
    <head>
      <title>Contactez Ibrahim Ouahabi | Développeur Web à Compiègne</title>
      <meta name="description" content="Vous avez un projet ou une question ? Contactez Ibrahim Ouahabi, développeur web à Compiègne, via ce formulaire de contact." />
      <meta name="keywords" content="Contact Développeur Web, Ibrahim Ouahabi, Compiègne, Formulaire de Contact" />
    </head>
    <section class="contact-introduction">
      <h1>Contactez-moi</h1>
      <p>Je suis disponible pour toute question ou collaboration. Remplissez le formulaire ci-dessous, et je vous répondrai dès que possible.</p>
    </section>

    <section class="contact-form">
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">Nom</label>
          <input type="text" id="name" v-model="form.name" @blur="validateName" required />
          <span v-if="errors.name" class="error">{{ errors.name }}</span>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="form.email" @blur="validateEmail" required />
          <span v-if="errors.email" class="error">{{ errors.email }}</span>
        </div>

        <div class="form-group">
          <label for="message">Message</label>
          <textarea id="message" v-model="form.message" @blur="validateMessage" required></textarea>
          <span v-if="errors.message" class="error">{{ errors.message }}</span>
        </div>

        <button type="submit" class="btn-submit" :disabled="!isFormValid">Envoyer</button>
      </form>
    </section>

    <div v-if="formSubmitted" class="notification">
      Merci pour votre message ! Je vous répondrai dès que possible.
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      errors: {
        name: '',
        email: '',
        message: ''
      },
      formSubmitted: false,
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.name && !this.errors.email && !this.errors.message;
    }
  },
  methods: {
    validateName() {
      if (!this.form.name) {
        this.errors.name = 'Veuillez entrer votre nom.';
      } else {
        this.errors.name = '';
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.form.email) {
        this.errors.email = 'Veuillez entrer votre adresse email.';
      } else if (!emailPattern.test(this.form.email)) {
        this.errors.email = 'Veuillez entrer une adresse email valide.';
      } else {
        this.errors.email = '';
      }
    },
    validateMessage() {
      if (!this.form.message) {
        this.errors.message = 'Veuillez entrer votre message.';
      } else {
        this.errors.message = '';
      }
    },
    submitForm() {
      if (this.isFormValid) {
        // Envoyer les données à votre API backend ici
        console.log('Formulaire envoyé', this.form);

        // Réinitialiser le formulaire après l'envoi
        this.form.name = '';
        this.form.email = '';
        this.form.message = '';
        this.formSubmitted = true;

        // Masquer la notification après quelques secondes
        setTimeout(() => {
          this.formSubmitted = false;
        }, 3000);
      } else {
        // Afficher les erreurs si le formulaire n'est pas valide
        this.validateName();
        this.validateEmail();
        this.validateMessage();
      }
    }
  }
};
</script>

<style scoped>
.contact-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.contact-introduction {
  text-align: center;
  margin-bottom: 40px;
}

.contact-introduction h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #4CAF50;
}

.contact-introduction p {
  font-size: 1.2em;
  color: #666;
}

.contact-form {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  font-family: inherit;
  color: #333;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.error {
  color: #e74c3c;
  font-size: 0.9em;
  margin-top: 5px;
  display: block;
}

.btn-submit {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-submit:hover:not(:disabled) {
  background-color: #45a049;
}

.notification {
  margin-top: 20px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-size: 1.2em;
}

.g-recaptcha {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .contact-introduction h1 {
    font-size: 2em;
  }

  .contact-form {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 1em;
  }

  .btn-submit {
    font-size: 1em;
    padding: 10px;
  }
}
</style>
