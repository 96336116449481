<template>
    <div class="services-page">
      <head>
      <title>Services de Développement Web et Dépannage Informatique à Compiègne</title>
      <meta name="description" content="Ibrahim Ouahabi propose des services de développement web, dépannage informatique et plus à Compiègne. Contactez-moi pour en savoir plus." />
      <meta name="keywords" content="Développement Web, Dépannage Informatique, Services Web, Ibrahim Ouahabi, Compiègne" />
      </head>
      <section class="services-introduction">
        <h1>Mes Services</h1>
        <p>Je propose une gamme de services pour répondre à vos besoins en développement web.</p>
      </section>
  
      <section class="services-list">
        <div v-for="service in services" :key="service.id" class="service-item">
          <h2>{{ service.title }}</h2>
          <p>{{ service.description }}</p>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "ServicesPage",
    data() {
      return {
        services: [
          {
            id: 1,
            title: "Développement Frontend",
            description: "Création de sites web modernes et responsives utilisant des technologies comme Vue.js et React."
          },
          {
            id: 2,
            title: "Développement Backend",
            description: "Développement de systèmes backend robustes avec Node.js et Symfony."
          },
          {
            id: 3,
            title: "Consultation en Sécurité",
            description: "Audit de sécurité pour identifier les vulnérabilités et renforcer la sécurité de vos applications."
          },
          {
            id: 3,
            title: "Dépannage Informatique",
            description: "Dépannage sur vos machines et périphériques informatiques pour résoudre les problèmes rapidement. Aussi disponible à distance. Disponible pour des Devis et des montage de tour informatique."
          }
          // Ajoutez d'autres services ici
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .services-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .services-introduction {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .services-introduction h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  .services-introduction p {
    font-size: 1.2em;
    color: #666;
  }
  
  .services-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .service-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .service-item h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .service-item p {
    color: #777;
    line-height: 1.6;
  }
  </style>
  